import { render, staticRenderFns } from "./BannerRight.vue?vue&type=template&id=3d547d4d&scoped=true"
import script from "./BannerRight.vue?vue&type=script&lang=js"
export * from "./BannerRight.vue?vue&type=script&lang=js"
import style0 from "./BannerRight.vue?vue&type=style&index=0&id=3d547d4d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d547d4d",
  null
  
)

export default component.exports